import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'common/components/GatsbyImage';
import Fade from 'react-reveal/Fade';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Card from 'common/components/Card';
import Button from 'common/components/Button';
import FeatureBlock from 'common/components/FeatureBlock';
import Container from 'common/components/UI/Container';
import BusinessAccountShopSectionWrapper from './businessAccountShopSection.style';

const BusinessAccountShopSection = ({
  row,
  col,
  title,
  description,
  textArea,
  btnStyle,
}) => {

  const Data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/agency/businessAccount/tablet-shop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image2: file(
        relativePath: { eq: "image/agency/businessAccount/mobile-app.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      agencyJson {
        aboutus {
          id
          title
          icon
        }
      }
    }
  `);

  return (
    <BusinessAccountShopSectionWrapper id="BusinessAccountShopSection">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <Box {...textArea}>
              <FeatureBlock
                  title={
                    <Heading
                        content="Descubre nuestra tienda online y disfruta de una cuenta profesional"
                        {...title}
                    />
                  }
                  description={
                    <Text
                        content="Consigue una cuenta profesional y aprovecha los descuentos exclusivos y precios especiales para tu empresa."
                        {...description}
                        as="h3"
                        className="descriptionAboutUsSection"
                    />
                  }
                  className="titleAboutUsSection"
              />
            </Box>
            <Box {...textArea}>
              <p>En Sirvent Productes, ofrecemos una amplia selección de productos industriales de alta calidad para satisfacer las necesidades de tu empresa.
                Nuestra cuenta profesional te brinda la oportunidad de acceder a descuentos especiales y precios exclusivos basados en los acuerdos comerciales establecidos.
              </p>
              <Fragment>
                <div className="aboutUsButtons">
                  <a href="https://sirventproductes.com/contact-us?subject=SOLICITAR%20CUENTA%20PROFESIONAL" target="_blank">
                    <Button title="SOLICITAR CUENTA PROFESIONAL" {...btnStyle} />
                  </a>
                </div>
              </Fragment>
            </Box>
          </Box>
          <Box className="col boxAboutUsImages" {...col}>
            <Card className="group-gallery">
              <Box className="col1">
                <Fade top delay={30}>
                  <GatsbyImage
                      src={
                        (Data.image1 !== null) | undefined
                            ? Data.image1.childImageSharp.gatsbyImageData
                            : {}
                      }
                      objectFit="contain"
                      alt="Refinity"
                  />
                </Fade>
                <Fade left delay={60}>
                  <GatsbyImage
                      src={
                        (Data.image2 !== null) | undefined
                            ? Data.image2.childImageSharp.gatsbyImageData
                            : {}
                      }
                      objectFit="contain"
                      alt="eSense RM"
                  />
                </Fade>
              </Box>
            </Card>
          </Box>
        </Box>
      </Container>
    </BusinessAccountShopSectionWrapper>
  );
};

BusinessAccountShopSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BusinessAccountShopSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
  },
  // About us section title default style
  title: {
    display: 'block',
    fontSize: '22px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#F7A61B',
    mb: '10px',
    textTransform: 'uppercase',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
};

export default BusinessAccountShopSection;
