import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { agencyTheme } from 'common/theme/agency';
import { ResetCSS } from 'common/assets/css/style';
import { GlobalStyle, AgencyWrapper } from 'containers/Home/home.style';
import Navbar from 'containers/Home/Navbar';
import BannerSection from 'containers/Home/BannerSection';
import FeatureSection from 'containers/Home/FeatureSection';
import BusinessAccountShopSection from 'containers/Home/BusinessAccountShopSection';
import AboutUsSection from 'containers/Home/AboutUsSection';
import WorkHistory from 'containers/Home/WorkHistory';
//import BlogSection from 'containers/Home/BlogSection';
//import TestimonialSection from 'containers/Home/TestimonialSection';
import TeamSection from 'containers/Home/TeamSection';
import VideoSection from 'containers/Home/VideoSection';
import FaqSection from 'containers/Home/FaqSection';
//import NewsletterSection from 'containers/Home/NewsletterSection';
import QualitySection from 'containers/Home/QualitySection';
import QualitySectionBaslac from 'containers/Home/QualitySectionBaslac';
import Footer from 'containers/Home/Footer';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import '@redq/reuse-modal/es/index.css';
import Seo from 'components/seo';
import 'common/assets/css/index.css';
import Clients from "containers/Home/Clients";
import CallToAction from 'containers/Home/CallToAction';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

const Home = () => {
    return (
        <ThemeProvider theme={agencyTheme}>
            <Fragment>
                <Seo
                    title="Sirvent Productes | Distribuidores de pintura y anexos profesionales"
                    description="En Sirvent Productes puedes encontrar la gama más amplia de pintura industrial, automoción y anexos"
                />
                <Modal />
                <ResetCSS />
                <GlobalStyle />
                {/* End of agency head section */}
                {/* Start agency wrapper section */}
                <AgencyWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar />
                        </DrawerProvider>
                    </Sticky>
                    <BannerSection />
                    <BusinessAccountShopSection />
                    <FeatureSection />
                    <AboutUsSection />
                    <WorkHistory />
                    {/* <BlogSection /> */}
                    <QualitySection />
                    <QualitySectionBaslac />
                    <VideoSection />
                    {/* <TestimonialSection /> */}
                    <TeamSection />
                    <FaqSection />
                    <Clients />
                    {/* <NewsletterSection /> */}
                    <CallToAction />
                    <Footer />
                    <NotificationContainer />
                </AgencyWrapper>
                {/* End of agency wrapper section */}
            </Fragment>
        </ThemeProvider>
    );
};
export default Home;
