import React from 'react';

import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Image from 'common/components/Image';

import SectionWrapper, { Content, ButtonWrap } from './callToAction.style';

import illustration3 from 'common/assets/image/agency/callToAction/illustration3.png';
import illustration4 from 'common/assets/image/agency/callToAction/illustration4.png';

const CallToAction = () => {
  const contactRoute = () =>{
    window.open('/contacto',"_self");
  }
  return (
    <SectionWrapper>
      <Container>
        <Content>
          <Heading
            as="h3"
            content="¿Tienes alguna pregunta? No dudes en contactarnos"
          />
          <ButtonWrap onClick={contactRoute}>
            <Image src={illustration3} alt="illustration3" />
            <Button title="CONTÁCTANOS AHORA" />
            <Image src={illustration4} alt="illustration4" />
          </ButtonWrap>
        </Content>
      </Container>
    </SectionWrapper>
  );
};

export default CallToAction;
