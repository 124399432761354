import React, {Fragment, useRef} from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Input from 'common/components/Input';
import Button from 'common/components/Button';
import Image from 'common/components/Image';
import AuditoriaModalWrapper from './auditoriaModal.style';
import LogoImage from 'common/assets/image/agency/logo.png';
import emailjs from "@emailjs/browser";
import { NotificationManager } from 'react-notifications';
import { closeModal } from '@redq/reuse-modal';

const AuditoriaModal = ({
  row,
  col,
  btnStyle,
  logoStyle,
  titleStyle,
  contentWrapper,
  descriptionStyle
}) => {
  const SubmitButtonGroup = () => (
    <Fragment>
      <Button className="default" title="ENVIAR" type="submit" {...btnStyle} />
    </Fragment>
  );

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_58me40n', 'template_xr6f5sc', form.current, 'zmGO9_WUxzFpFfmMJ')
        .then(
            () => {
              NotificationManager.success('Tu petición se ha enviado con éxito.', 'Exisoto!', 3000);
            },
            () => {
              NotificationManager.error('Error al enviar el formulario', 'Error!', 3000);
            }
        );
    setTimeout(function() {
      closeModal();
    }, 3000)
  }

  const LabelTextPrivacyPolicy = () => (
      <div className="display-inline mb-6">
        <Input inputType="checkbox" isMaterial inputName="privacity" className="display-inline form-control" required />
        <span className="ml-10">Acepto la <a className="text-ckeckbox-privacity" href='/politica-privacidad'>política de privacidad</a></span>
      </div>
  );

  const AuditoriaFormData = () => (
      <form ref={form} className="form-contact text-left" onSubmit={sendEmail}>
        <Input inputType="text" isMaterial label="Nombre completo" className="input-form-auditoria" inputName="fullname" required />
        <Input inputType="email" isMaterial label="Correo Electrónico" className="input-form-auditoria" inputName="email" required />
        <Input inputType="text" isMaterial label="Nombre del taller" className="input-form-auditoria" inputName="workshop" required />
        <Input inputType="number" isMaterial label="Teléfono" className="input-form-auditoria" inputName="phone" required />
        <Input inputType="text" isMaterial label="Provincia" className="input-form-auditoria" inputName="province" required />
        <Input inputType="text" isMaterial label="País" className="input-form-auditoria" inputName="country" required />
        <Input inputType="textarea" isMaterial label="Anotaciones" className="input-form-auditoria" inputName="annotations" />
        <LabelTextPrivacyPolicy />
        <div className="text-center">
          <SubmitButtonGroup />
        </div>
      </form>
  );

  return (
    <AuditoriaModalWrapper id="auditoriaModalSection">
      <Box className="row" {...row}>
        <Box className="col tabCol" {...col}>
          <Box className="text-center" {...contentWrapper}>
            <Image src={LogoImage} {...logoStyle} alt="Logo" className="logo-modal-auditoria" />
              <Heading content="Quiero un análisis de rentabilidad" {...titleStyle} />
              <Text
                content="Si te interesa tener un análisis de rentabilidad para tu taller de chapa y pintura, ponte en contacto con nosotros sin ningún compromiso."
                {...descriptionStyle}
              />
              <AuditoriaFormData />
          </Box>
        </Box>
      </Box>
    </AuditoriaModalWrapper>
  );
};

// LoginModal style props
AuditoriaModal.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  hintTextStyle: PropTypes.object,
  contentWrapper: PropTypes.object,
  descriptionStyle: PropTypes.object,
};

// AuditoriaModal default style
AuditoriaModal.defaultProps = {
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Team member col default style
  col: {
    width: [1],
  },
  // Default logo size
  logoStyle: {
    width: '175px',
    height: 'auto',
    ml: '15px',
  },
  // Title default style
  titleStyle: {
    fontSize: ['22px', '36px', '50px'],
    fontWeight: '400',
    color: '#20201D',
    letterSpacing: '-0.025em',
    mt: '35px',
    mb: '10px',
  },
  // Description default style
  descriptionStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '-0.025em',
    mb: '23px',
    ml: '1px',
  },
  // Content wrapper style
  contentWrapper: {
    pt: ['32px', '56px'],
    pl: ['17px', '32px', '38px', '40px', '56px'],
    pr: '32px',
    pb: ['32px', '56px']
  },
  // Default button style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  // Outline button outline style
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#F7A61B',
  },
};

export default AuditoriaModal;
