import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import FeatureBlock from 'common/components/FeatureBlock';
import Container from 'common/components/UI/Container';
import Particles from '../Particle';
import BannerWrapper /*, { DiscountLabel }*/ from './bannerSection.style';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  //discountText,
  //discountAmount,
  outlineBtnStyle,
}) => {
  const EcommerceRoute = () =>{
    window.open('https://sirventproductes.com', '_blank');
  }
  const ButtonGroup = () => (
    <Fragment>
        <Button
            onClick={EcommerceRoute}
            title="IR A LA TIENDA"
            aria-label="Ir a la tienda"
            {...btnStyle}
        />
    </Fragment>
  );
  return (
    <BannerWrapper id="bannerSection">
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            {/*<DiscountLabel>
              <Text content="25% Discount" {...discountAmount} />
              <Text content="on every first project budget" {...discountText} />
            </DiscountLabel>*/}
            <FeatureBlock
              title={
                <Heading
                  as="h1"
                  content="Distribuidores de pintura y anexos profesionales"
                  {...title}
                />
              }
              description={
                <Text
                  content="En Sirvent Productes puedes encontrar la gama más amplia de pintura industrial, automoción y anexos."
                  as="h3"
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '70%', '60%', '50%'],
  },
  title: {
    display: 'block',
    fontSize: '38px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#F7A61B',
    mb: '10px',
    textTransform: 'uppercase',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#F7A61B',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
