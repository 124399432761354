import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ClientsArea from './clients.style';
import BrandRM from 'common/assets/image/agency/client/rm.png';
import BrandCindis from 'common/assets/image/agency/client/CINDIS.png';
import BrandMirka from 'common/assets/image/agency/client/mirka.png';
import BrandRodim from 'common/assets/image/agency/client/rodim.png';
import BrandSata from 'common/assets/image/agency/client/sata.png';
import Brand3M from 'common/assets/image/agency/client/3M.png';
import BrandAkzoNobel from 'common/assets/image/agency/client/akzo-nobel.png';
import BrandAnestIwata from 'common/assets/image/agency/client/anest-Iwata.png';
import BrandColad from 'common/assets/image/agency/client/colad.png';
import BrandFestool from 'common/assets/image/agency/client/Festool.png';
import BrandReauxi from 'common/assets/image/agency/client/reauxi.png';
const Clients = () => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        brands {
          id
          alt
        }
      }
    }
  `);
    const ImagesBrands = [BrandRM, BrandCindis, BrandMirka, BrandRodim, BrandSata, Brand3M, BrandAkzoNobel, BrandAnestIwata, BrandColad, BrandFestool, BrandReauxi]
    return (
    <ClientsArea id="brandsSection">
      <Container>
        <Heading as="h2" content="Nuestras marcas" />
        <Box className="imageWrap">
          {Data.agencyJson.brands.map((brand, index) => (
            <div className='client-image-wrapper m-w-100' key={`client-image-key-${index}`}>
              <Image
                src={ImagesBrands[index]}
                alt={brand.alt}
                className="ml-20"
              />
            </div>
          ))}
        </Box>
      </Container>
    </ClientsArea>
  );
};

export default Clients;
