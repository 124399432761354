import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'common/components/GatsbyImage';
import Fade from 'react-reveal/Fade';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Card from 'common/components/Card';
import Button from 'common/components/Button';
import FeatureBlock from 'common/components/FeatureBlock';
import Container from 'common/components/UI/Container';
import AboutUsSectionWrapper from './aboutUsSection.style';
import PDFAuditoria from 'common/assets/files/auditoria.pdf';
import { openModal, closeModal } from '@redq/reuse-modal';
import AuditoriaModal from '../AuditoriaModal';

// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const AboutUsSection = ({
  row,
  col,
  title,
  description,
  textArea,
  featureTitle,
  btnStyle,
  outlineBtnStyle,
}) => {

  // Auditoria modal handler
  const handleAuditoriaModal = () => {
    openModal({
      config: {
        className: 'login-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
        animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
        animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
        transition: {
          mass: 1,
          tension: 180,
          friction: 26,
        },
      },
      component: AuditoriaModal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: false,
    });
  };

  const Data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/agency/group/refinity.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image2: file(
        relativePath: { eq: "image/agency/group/productividad-mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      image3: file(
        relativePath: { eq: "image/agency/group/e-sense.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      agencyJson {
        aboutus {
          id
          title
          icon
        }
      }
    }
  `);

  return (
    <AboutUsSectionWrapper id="AboutUsSection">
      <Container>
        <Box className="row" {...row}>
          <Box className="boxAboutUsImages col" {...col}>
            <Card className="group-gallery">
              <Box className="col1">
                <Fade top delay={30}>
                  <GatsbyImage
                    src={
                      (Data.image1 !== null) | undefined
                        ? Data.image1.childImageSharp.gatsbyImageData
                        : {}
                    }
                    objectFit="contain"
                    alt="Refinity"
                  />
                </Fade>
                <Fade left delay={60}>
                  <GatsbyImage
                    src={
                      (Data.image3 !== null) | undefined
                        ? Data.image3.childImageSharp.gatsbyImageData
                        : {}
                    }
                    objectFit="contain"
                    alt="eSense RM"
                  />
                </Fade>
              </Box>
              <Box className="col2">
                <Fade bottom delay={90}>
                  <GatsbyImage
                    src={
                      (Data.image2 !== null) | undefined
                        ? Data.image2.childImageSharp.gatsbyImageData
                        : {}
                    }
                    objectFit="contain"
                    alt="Rentabilidad del taller"
                  />
                </Fade>
              </Box>
            </Card>
          </Box>
          <Box className="col" {...col}>
            <Box {...textArea}>
              <FeatureBlock
                title={
                  <Heading
                    content="¿Quieres evolucionar tu taller?"
                    {...title}
                  />
                }
                description={
                  <Text
                    content="En Sirvent Productes ponemos a disposición de nuestros clientes una serie de servicios y planes de mejora para el taller de pintura."
                    {...description}
                    as="h3"
                    className="descriptionAboutUsSection"
                  />
                }
                className="titleAboutUsSection"
              />
            </Box>
            <Box {...textArea}>
              {Data.agencyJson.aboutus.map((feature, index) => (
                <FeatureBlock
                  key={`feature_point-${index}`}
                  icon={<i className={feature.icon} />}
                  iconPosition="left"
                  title={<Heading content={feature.title} {...featureTitle} />}
                />
              ))}
              <Fragment>
                <div className="aboutUsButtons">
                  <a href={PDFAuditoria} download="EjemploAuditoria.pdf">
                    <Button title="VER EJEMPLO" {...btnStyle} />
                  </a>
                  <Button
                      title="PEDIR UN ANÁLISIS DE RENTABILIDAD"
                      variant="textButton"
                      icon={<i className="flaticon-next" />}
                      onClick={handleAuditoriaModal}
                      {...outlineBtnStyle}
                  />
                </div>
              </Fragment>
            </Box>
          </Box>
        </Box>
      </Container>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
  },
  // About us section title default style
  title: {
    display: 'block',
    fontSize: '22px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#F7A61B',
    mb: '10px',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
};

export default AboutUsSection;
